<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">Dashboard</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">Dashboard</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

         <div v-else class="container-fluid">
            <!-- Info boxes -->
            <div v-if="userProfile === 'partner'" class="row">
               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-success elevation-1"><i class="fas fa-tags"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">{{ $t('total_of_used_coupons') }}</span>
                        <span class="info-box-number">{{ totalPartnerCoupons }}</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->
            </div>
            <div v-else class="row">
               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-success elevation-1"><i class="fas fa-money-bill-alt"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">{{ $t('total_in_current_monthly') }}</span>
                        <span class="info-box-number">R$ {{ totalMonthlyRentFormated }}</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->

               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-comment"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">{{ `${$t('comments')}` }}</span>
                        <span class="info-box-number">{{ totalComments }}</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->

               <!-- fix for small devices only -->
               <div class="clearfix hidden-md-up"></div>

               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-primary elevation-1"><i class="fas fa-shopping-cart"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">{{ `${$t('active_signatures')}` }}</span>
                        <div class="row">
                           <span style="font-size: 13px" class="info-box-number mx-2">
                              {{ totalPaidSignatures }} {{ $t('paid_for') }}
                           </span>
                           <span style="font-size: 13px; margin-top: 1.5px" class="text-sm font-italic font-weight-bolder gray"> e </span>
                           <span style="font-size: 13px" class="info-box-number ml-2">
                              {{ totalFreeSignatures }} {{ $t('free_of_charge') }}
                           </span>
                        </div>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->
               <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box mb-3">
                     <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>

                     <div class="info-box-content">
                        <span class="info-box-text">{{ `${$t('clients')}` }}</span>
                        <span class="info-box-number">{{ totalClients }}</span>
                     </div>
                     <!-- /.info-box-content -->
                  </div>
                  <!-- /.info-box -->
               </div>
               <!-- /.col -->
            </div>
            <!-- /.row -->

            <div v-if="userProfile === 'admin'" class="row">
               <div class="col-md-12">
                  <div class="card">
                     <div class="card-header">
                        <h5 class="card-title">{{ $t('report') }}</h5>

                        <div class="card-tools">
                           <button type="button" class="btn btn-tool" data-card-widget="collapse">
                              <i class="fas fa-minus"></i>
                           </button>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div class="card-body">
                        <div class="row">

                           <div class="col-4">
                              <div class="form-group">
                                 <label>Tipo de Relatório</label>
                                 <select v-model="reportType" class="form-control">
                                    <option disabled value="">Tipo</option>
                                    <option value="financialReport"> Financeiro Anual </option>
                                    <option value="monthlyReport"> Detalhado Mensal </option>
                                 </select>
                              </div>
                           </div>

                           <div class="col-4">
                              <div class="form-group">
                                 <label>Ano</label>
                                 <select v-model="selectedYear" class="form-control">
                                    <option disabled value="">Ano</option>
                                    <option v-for="(year, index) in years" :value="year" :key="index"> {{ year }} </option>
                                 </select>
                              </div>
                           </div>

                           <div class="form-group mx-4 pt-2 mt-4">
                              <router-link v-if="reportType === 'monthlyReport'" :to="{ name: 'monthlyReports', params: { year: selectedYear } }" class="btn btn-primary btn-sm" > <i class="fas fa-file-pdf"></i> Gerar PDF </router-link>
                              <router-link v-else :to="{ name: 'yearlyReports', params: { year: selectedYear } }" class="btn btn-primary btn-sm" > <i class="fas fa-file-pdf"></i> Gerar PDF </router-link>
                           </div>

                        </div>
                        <!-- /.row -->
                     </div>
                     <!-- ./card-body -->

                     <div class="card-footer">

                     </div>
                     <!-- /.card-footer -->
                  </div>
                  <!-- /.card -->
               </div>
               <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
               <div class="col-md-12">
                  <div class="card">
                     <div class="card-header">
                        <h5 class="card-title">{{ userProfile === 'admin' ? `${$t('graphics')} / ${$t('values')}` : $t('graphic') }}</h5>

                        <div class="card-tools">
                           <button type="button" class="btn btn-tool" data-card-widget="collapse">
                              <i class="fas fa-minus"></i>
                           </button>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div class="card-body">
                        <div class="row">
                           <div
                              :class="[ userProfile === 'partner' ? 'col-md-12' : 'col-md-8' ]"
                           >
                              <p v-if="userProfile === 'partner'" class="text-center">
                                 <strong>{{ `${$t('quantity_of_coupons_per_month')}` }}</strong>
                              </p>
                              <p v-else class="text-center">
                                 <strong>{{ `${$t('quantity_of')} ${$t('coupons')}` }} {{ $t('used_in') }} {{ currentMonth }} - {{ currentYear }}</strong>
                              </p>

                              <div v-if="tableData.length <= 0" class="d-flex justify-content-center">{{ $t('no_coupons_used_this_month_for_new_sig') }}</div>
                              <div v-else class="chart">
                                 <!-- Sales Chart Canvas -->
                                 <canvas id="myChart" height="120" style="height: 120px;"></canvas>
                              </div>
                              <!-- /.chart-responsive -->
                           </div>
                           <!-- /.col -->

                           <div v-if="userProfile === 'admin'" class="col-md-4 mt-3">
                              <p class="text-center">
                                 <strong>{{ $t('qty_of_signatures') }} - {{ $t('Obtained') }}</strong>
                              </p>

                              <div v-for="(sig, idx) in qtySignaturesByMonth" :key="idx" class="progress-group">
                                 {{ getMonthName(sig.month) }}
                                 <span class="float-right"><b>{{ sig.qty }}</b></span>
                                 <div class="progress progress-sm">
                                    <div class="progress-bar bg-gradient-info" style="width: 100%"></div>
                                 </div>
                              </div>
                              <!-- /.progress-group -->
                           </div>
                           <!-- /.col -->

                        </div>
                        <!-- /.row -->

                     </div>
                     <!-- ./card-body -->

                     <div v-if="userProfile === 'admin'" class="card-footer">
                        <p class="text-center">
                           <strong>{{ $t('amount_collected') }}</strong>
                        </p>
                        <div class="row">
                           <div v-for="(sigValue, idx) in totalSignaturesByMonth" :key="idx" class="col-sm-3 col-6">
                              <div class="description-block border-right">
                                 <h5 class="description-header">R$ {{ sigValue.total }}</h5>
                                 <span class="description-text">{{ getMonthName(sigValue.month) }}</span>
                              </div>
                              <!-- /.description-block -->
                           </div>
                        </div>
                        <!-- /.row -->
                     </div>
                     <!-- /.card-footer -->
                  </div>
                  <!-- /.card -->
               </div>
               <!-- /.col -->
            </div>
            <!-- /.row -->

         </div><!--/. container-fluid -->
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import reportService from "../services/report-service";
import { mapState, mapActions } from "vuex";
import {getMonthName} from "../../../utils/get-month-name";

export default {
   name: "Reports",
   data () {
      return {
         totalPartnerCoupons: null,
         partnerCouponsPerMonth: null,
         isLoading: false,
         years: [],
         selectedYear: 0,
         reportType: 'financialReport'
      }
   },
   computed: {
      ...mapState({
         userProfile: state => state.auth.user.data.profile,
         userId: state => state.auth.user.data.userId,
         getTableInfo: state => state.report.reports.getTableInfo,
         tableLabels: state => state.report.reports.tableLabels,
         tableData: state => state.report.reports.tableData,
         totalMonthlyRent: state => state.report.reports.totalMonthlyRent,
         totalClients: state => state.report.reports.totalClients,
         totalComments: state => state.report.reports.totalComments,
         totalFreeSignatures: state => state.report.reports.totalFreeSignatures,
         totalPaidSignatures: state => state.report.reports.totalPaidSignatures,
         totalSignaturesByMonth: state => state.report.reports.totalSignaturesByMonth,
         qtySignaturesByMonth: state => state.report.reports.qtySignaturesByMonth
      }),
      currentYear() {
         const now = new Date();
         return now.getFullYear();
      },
      currentMonth() {
         const now = new Date();
         const month = (now.getMonth() + 1);
         const monthName = getMonthName(month);
         return monthName;
      },
      totalMonthlyRentFormated () {
         const total = this.totalMonthlyRent.toLocaleString('pt-br', {minimumFractionDigits: 2});

         return total;
      }
   },
   async created() {
      const date = new Date();
      this.selectedYear = date.getFullYear();

      this.years = await reportService.getYearsFromSignatures();

      if (this.userProfile === "partner") {
         this.isLoading = true;
         this.totalPartnerCoupons = await reportService.getTotalOfTimesThePartnerCouponWasUsed(this.userId);
         this.partnerCouponsPerMonth = await reportService.getQtyOfTimesByMonthThePartnerCouponWasUsed(this.userId);
      }
      else {
         if (this.getTableInfo.length === 0){
            debugger
            await this.getAdminTableInformation();
         }
      }

      this.isLoading = false;

      setTimeout(() => {
         const ctx = document.getElementById('myChart');

         if (this.userProfile === 'partner') {
            const labels = [];
            const data = [];

            Object.entries(this.partnerCouponsPerMonth).map(([key, value]) => {
               const month = key.split("-")[1]; // Split the key on the '-' and take the second part (the month)
               const monthName = getMonthName(month);
               labels.push(monthName);
               data.push(value);
            });

            new Chart(ctx, {
               type: 'bar',
               data: {
                  labels: labels,
                  datasets: [{
                     label: 'Quantidade por mês',
                     data: data,
                     borderWidth: 1
                  }]
               },
               options: {
                  scales: {
                     y: {
                        beginAtZero: true
                     }
                  }
               }
            });
         }
         else {
            new Chart(ctx, {
               type: 'doughnut',
               data: {
                  labels: this.tableLabels,
                  datasets: [{
                     label: 'Quantidade de cupons',
                     data: this.tableData,
                     backgroundColor: [
                        'rgb(229,146,165)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                        'rgb(255, 137, 86)',
                        'rgb(193, 86, 255)',
                        'rgb(255,86,86)',
                        'rgb(103,255,86)',
                        'rgb(165,224,183)',
                     ],
                     hoverOffset: 4
                  }]
               }
            });
         }
      }, 1000)
   },
   methods: {
      getMonthName,
      ...mapActions(['getAdminTableInformation'])
   }
}
</script>
